import React from 'react'

import { ThirdrdeconomyHead } from '@/components/3rdeconomy/3rdeconomyHead'
import { StyledLink } from '@/components/link'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header/3rdeconomy'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer/3rdeconomy'
import { cssThirdrdeconomyButton } from '@/components/3rdeconomy/linkStyles'
import { PublicNoticeList } from '@/components/common/publicnotice'

import { publicNoticeList } from '@/constants/publicNotice/3rdeconomy'

import header_3rdeconomy_logo from '@/images/3rdeconomy/logo-corporate.svg'
import arrow_white from '@/images/arrow_white.svg'

export default function ThirdrdeconomyNews(): JSX.Element {
  return (
    <>
      <ThirdrdeconomyHead title="株式会社3rd Economy" url="/group/3rdeconomy" />
      <HeaderNav
        css={cssHeaderNav}
        url="/group/3rdeconomy"
        imgSrc={header_3rdeconomy_logo}
        alt="3rd Economy"
        navList={headerNavList}
        gradientColor={[color.green2, color.blue8]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.white1}
        headerLogoCss={cssHeaderLogoSize}
        breakpoint={768}
      />
      <section id="public-notice" css={cssSection}>
        <div css={[wrapGray, cssLayoutInr]}>
          <h2 css={[cssH2, cssH2Up]}>
            <span>PUBLIC NOTICE</span>
            <br />
            <span>電子公告</span>
          </h2>
          <div css={cssLayout}>
            <div css={cssList}>
              <PublicNoticeList publicNoticeList={publicNoticeList} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div css={[cssCompanyLayoutInr]}>
          <h2 css={[cssH2, cssH2Up]}>
            <span>CONTACT</span>
            <br />
            <span>お問い合わせ</span>
          </h2>
          <div css={cssContactLayout}>
            <p css={[cssParagraph, cssContactParagraph]}>
              株式会社3rd
              Economyに関するお問い合わせはこちらからご連絡ください。
            </p>
            <div css={cssCenter}>
              <StyledLink
                href="https://relic.co.jp/contact/"
                theme={cssThirdrdeconomyButton}
                src={arrow_white}
              >
                お問い合わせはこちら
              </StyledLink>
            </div>
          </div>
        </div>
      </section>
      <FooterBottom footerBottomList={footerBottomList} />
    </>
  )
}

// ヘッダー
const cssHeaderNav = css`
  padding: 15px 16px;
  font-family: 'Jost', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  box-shadow: 0 3px 6px ${color.gray1};
  ${mq.pc} {
    box-shadow: 0 0 16px ${color.gray1};
  }
`

const cssHeaderLogoSize = css`
  width: 44px;
  ${mq.mid} {
    width: 60px;
  }
`

const cssBtnFontStyle = css`
  font-weight: 600;
  color: ${color.white1};
`
// ヘッダーここまで

// セクションタイトル
const cssSection = css`
  padding-top: 165px;

  ${mq.pc} {
    padding-top: 281px;
  }
`

const cssH2 = css`
  position: relative;
  z-index: 1;
  color: ${color.black2};
  text-align: center;
  span {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-weight: 700;
    ${mq.pc} {
      font-size: 54px;
      letter-spacing: 0.054em;
    }

    &:last-child {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
      ${mq.pc} {
        font-size: 28px;
        letter-spacing: 2.8px;
      }
    }
  }
`

const cssH2Up = css`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;

  ${mq.pc} {
    top: -40px;
  }
`
// セクションタイトルここまで

// 電子公告
const cssLayout = css`
  padding: 88px 0 100px;
  ${mq.pc} {
    padding: 137px 0 183px;
  }
`

const cssList = css`
  max-width: 900px;
  margin: 0 auto;
`

const cssLayoutInr = css`
  position: relative;
  padding: 0 20px;
`

const wrapGray = css`
  background: ${color.white4};
`
// 電子公告ここまで

// お問い合わせ
const cssContactLayout = css`
  position: relative;
  padding: 72px 16px 43px;
  ${mq.pc} {
    padding: 105px 16px 123px;
  }
`

const cssParagraph = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: ${color.black1};
  letter-spacing: 1px;
  ${mq.pc} {
    text-align: center;
  }
`

const cssContactParagraph = css`
  position: relative;
  margin: 16px auto;
  ${mq.pc} {
    margin: 20px auto 30px;
  }
`

const cssCenter = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const cssCompanyLayoutInr = css`
  position: relative;
  padding: 0 16px;
`
// お問い合わせここまで