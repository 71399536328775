import React, { useEffect } from 'react'

import { PublicNoticeType } from '@/typedir/types'
import { css } from '@emotion/react'
import iconPdf from '@/images/icon_pdf.svg'
import { mq } from '@/components/media/media'

type Props = {
  publicNoticeList: PublicNoticeType[]
}

export const PublicNoticeList = ({ publicNoticeList }: Props): JSX.Element => {
  const [publicNotices, setPublicNotices] = React.useState(
    publicNoticeList.map((publicNotice) => ({
      ...publicNotice,
      isOpen: false
    }))
  )
  useEffect(() => {
    const items = document.querySelectorAll('.public-notice-item')
    items.forEach((item, index) => {
      item.addEventListener('click', () => {
        item.classList.toggle('active')

        const opened = item.classList.contains('active')
        setPublicNotices((prev) =>
          prev.map((notice, i) =>
            i === index ? { ...notice, isOpen: opened } : notice
          )
        )
        const content = item.querySelector(
          '.public-notice-item__content'
        ) as HTMLElement
        content.style.gridTemplateRows = opened ? '1fr' : '0fr'
      })
    })
  }, [])

  return (
    <ul css={cssList}>
      {publicNotices.map((publicNotice, index) => (
        <li className={'public-notice-item'} css={cssItem} key={index}>
          <div
            css={
              publicNotice.isOpen
                ? [cssHeadline, cssHeadlineIsOpen]
                : cssHeadline
            }
          >
            <h3 css={cssYear}>{publicNotice.year}</h3>
          </div>
          <div className={'public-notice-item__content'} css={cssContent}>
            <div css={cssContentInner}>
              {publicNotice.data.map((data) => (
                <p>
                  <span css={cssDate}>{data.date}</span>
                  <a css={cssLink} href={data.url}>
                    {data.text}
                  </a>
                </p>
              ))}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

const cssList = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const cssItem = css`
  box-shadow: 0px 0px 10px 0px #00000026;
`

const cssHeadline = css`
  display: block flow;
  position: relative;
  background-color: #fff;
  padding: 16px 19px;
  cursor: pointer;

  ${mq.pc} {
    padding: 32px 26px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 11px;
    width: 16px;
    height: 2px;
    background: #212121;
    border-radius: 2px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    ${mq.pc} {
      right: 25px;
      width: 22px;
    }
  }

  &::after {
    transform: rotate(90deg);
  }
`

const cssHeadlineIsOpen = css`
  &::before {
    opacity: 0;
  }

  &::after {
    transform: rotate(0);
  }
`

const cssYear = css`
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.05em;

  ${mq.pc} {
    font-size: 26px;
  }
`

const cssContent = css`
  display: block grid;
  background-color: #f4f4f4;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s;
`

const cssContentInner = css`
  overflow: hidden;

  p {
    padding: 22px 19px 17px;
    ${mq.pc} {
      padding: 33px 57px;
    }
  }
`

const cssDate = css`
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.05em;

  ${mq.pc} {
    font-size: 20px;
  }
`

const cssLink = css`
  display: block;
  position: relative;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-decoration: underline;
  color: #0071c5;
  margin-top: 12px;

  ${mq.pc} {
    display: inline;
    font-size: 20px;
    margin-top: 0;
    margin-left: 18px;
  }

  &::after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(${iconPdf}) center no-repeat;
    bottom: 0;
    margin-left: 7px;

    ${mq.pc} {
      width: 22px;
      height: 22px;
      margin-left: 4px;
    }
  }
`
