import { PublicNoticeType } from '@/typedir/types'
import pdf_2024_1 from '@/images/3rdeconomy/3rdeconomy_publicnotice_240830.pdf'
export const publicNoticeList: PublicNoticeType[] = [
  {
    year: '2024年',
    data: [
      { date: '2024年9月30日', text: '第一期決算公告', url: pdf_2024_1 }
    ]
  }
]
